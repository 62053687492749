$sm-screen: 639px;

[ez-dsar-button] {
  display: none;
}

.ez-container {
  @media (max-width: $sm-screen) {
    padding: 0px !important;
  }
}

.cookieManagerButton {
  @media (max-width: $sm-screen) {
    right: 20px;
    left: auto;
  }
}
