@import "media-queries.scss";

:root {
  --success-pri: #34a16d;
  --success-sec: #87cdab;
  --error-pri: #e3005f;
  --gray-pri: #757575;
  --gray-sec: #d9d9d9;
  --gray-thi: #f4f4f4;
}

body {
  font-size: 1rem;
}

.border-gray-sec {
  border: 1px solid var(--gray-sec);
}

.border-bottom-gray-sec {
  border-bottom: 1px solid var(--gray-sec);
}

.border-bottom-black {
  border-bottom: 1px solid #000;

  td {
    border-bottom: 2px solid #000;
  }
}

.border-success-right {
  border-right: 8px solid var(--success-pri);
}

.border-success {
  border: 2px solid var(--success-pri);
}

.text-gray-pri {
  color: var(--gray-pri);
}

.text-success-pri {
  color: var(--success-pri);
}

.text-danger {
  color: var(--error-pri);
}

.bg-gray-thi {
  background: var(--gray-thi);
}

.success-btn {
  background: var(--success-pri);
  color: white;
  padding: 12px 0px;
  font-weight: 600;
  width: 140px;
  text-align: center;
  font-size: 16px;

  &:disabled {
    background: var(--success-sec);
  }

  @include lt-sm {
    font-size: 14px;
  }
}

.error-btn {
  background: var(--error-pri);
  color: white;
  padding: 12px 0px;
  font-weight: 600;
  width: 140px;
  text-align: center;

  @include lt-sm {
    font-size: 14px;
  }
}

.outline-btn {
  border: 1px solid var(--gray-pri);
  color: var(--gray-pri);
  padding: 12px 0px;
  font-weight: 600;
  width: 140px;
  text-align: center;

  &:disabled {
    border-color: var(--gray-sec);
    color: var(--gray-sec);
  }
}

.outline-btn-black {
  border: 1px solid #000;
  color: #000;
  padding: 12px 0px;
  font-weight: 600;
  width: 140px;
}

input,
textarea {
  border: 1px solid var(--gray-sec);
  padding: 12px 8px !important;

  &.invalid {
    border-color: var(--error-pri);
  }
}

textarea {
  resize: none !important;
}

input[type="radio"] {
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px;
  accent-color: var(--success-pri);
}

input:focus,
textarea:focus {
  outline: none;
}

.main {
  background: url(../img/desktop_bg.png) center center / cover no-repeat;

  @keyframes back-motion {
    from {
      background-position-x: 0;
    }
    to {
      background-position-x: -45vw;
    }
  }

  &.policy {
    background-image: url(../img/desktop_bg2.png);
  }

  &.animated {
    animation: back-motion 0.5s ease-in-out;
  }

  &.tab-active {
    background: url(../img/desktop_bg.png) -45vw center / cover no-repeat;
  }

  @include lt-sm {
    background: url(../img/mobile_bg.jpg) center center / cover no-repeat;

    &.burger-active:after {
      content: "";
      background: url(../img/skyline.jpg) center center / cover no-repeat;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.nav-menu {
  position: relative;

  &.active {
    display: flex !important;
    flex-direction: column;
    position: absolute;
    top: 10vh;
    left: 0px;
    width: 100% !important;
    z-index: 10;
    gap: 24px;
    background: white;
    padding: 36px 0px;
    border-bottom: 4px solid var(--success-pri);
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &.active {
      border-left: 1px solid #d9d9d9;
      border-right: 1px solid #d9d9d9;
      outline: 1px solid white;
    }
  }

  .active-marker {
    position: absolute;
    top: 0;
    height: 5px;
    background-color: var(--success-pri);
    width: 0;
    transition: left 0.8s ease, width 0.8s ease;

    @include lt-sm {
      display: none;
    }
  }
}

.service-6:hover {
  @apply border-success;
  cursor: pointer;
}

sup {
  font-size: 125% !important;
  top: 40% !important;
}

.input-desc.required:after {
  @apply text-danger;
  content: "*";
  margin-left: 4px;
  font-size: 125%;
}

li {
  padding-left: 4px;
  position: relative;

  &.active::before {
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    left: -40px;
    width: 8px;
    height: 24px;
    background: var(--success-pri);
  }
}

// For vh heights
.h-\[10vh\] {
  height: calc(var(--vh, 1vh) * 10) !important;
}

.h-\[70vh\] {
  height: calc(var(--vh, 1vh) * 70) !important;
}

.h-\[90vh\] {
  height: calc(var(--vh, 1vh) * 90) !important;
}

.h-fit-content {
  height: fit-content;
}

// For customizing scrolls
*::-webkit-scrollbar-track {
  background-color: #f5f5f546;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f532;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--gray-sec);
  border: 2px solid var(--gray-thi);
}

// for hamburger button
$burger-width: 20px;
$burger-height: 20px;
$burger-thickness: 1px;
$burger-color: #000;

.burger {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  width: $burger-width;
  height: $burger-height;
  cursor: pointer;
  overflow: hidden;

  span {
    width: 100%;
    height: $burger-thickness;
    background-color: $burger-color;
    transition: all 0.25s;
  }

  &.active {
    span {
      &:nth-child(1) {
        transform: translateY(calc($burger-height / 2) - $burger-thickness)
          rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: translateY(calc(-1 * $burger-height / 2)) rotate(-45deg);
      }
    }
  }
}

// For customizing react-datepicker
.cteg-datepicker {
  border: none;
}

.react-datepicker {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  border: none !important;

  &__triangle {
    stroke: none !important;
    fill: #f4f4f479 !important;
  }

  &__header {
    background-color: #f4f4f479 !important;
  }

  &__day--selected,
  &__day--keyboard-selected {
    background-color: var(--success-sec) !important;
  }
}
