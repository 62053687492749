.table-container {
  border-top: 8px solid var(--success-pri);

  .title {
    padding: 20px 12px;
    font-weight: 700;
    text-align: center;
    border-left: 1px solid black;
    border-right: 1px solid black;
  }
}

table {
  width: 100%;
}

table.input-table {
  font-size: 16px;

  th {
    text-align: center;
    line-height: 120%;
  }

  tr[disabled] {
    * {
      color: var(--gray-pri);
      -webkit-user-modify: read-only !important;
    }

    td:first-child:not(:empty):after {
      background-image: url(../img/plus.png);
    }
  }

  td {
    &:after {
      content: "";
    }

    &:first-child:not(:empty):after {
      float: right;
      width: 20px;
      height: 20px;
      margin-top: 3px;
      background-image: url(../img/close.png);
      cursor: pointer;
    }

    &.disabled {
      color: var(--gray-pri);
    }

    &:not(tr:last-child td):not(.uneditable) {
      -webkit-user-modify: read-write-plaintext-only;
      -moz-user-modify: read-write-plaintext-only;
    }

    &:empty:before {
      content: attr(data-placeholder);
      color: var(--gray-pri);
      font-weight: 400;
    }

    &:focus {
      outline: none;
    }
  }

  &.convertible-debt tr:last-child td:after {
    content: none;
  }
}

th,
col {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: start;
}

td {
  padding: 6px 12px;
  text-align: right;

  button:disabled {
    color: var(--gray-pri);
    cursor: not-allowed;

    img {
      filter: brightness(0.5);
    }
  }

  input {
    padding: 0px !important;
    width: 100%;
    background-color: transparent;
  }
}

td:first-child {
  text-align: left;
}

tr {
  &.total {
    border: 1px solid black;
  }

  &.text-center * {
    text-align: center;
  }
}

th,
tr.total td {
  padding: 20px 12px;
  font-weight: 700;
}

table.striped tr:nth-child(even) {
  background: var(--gray-thi);
}

table .editable {
  color: var(--success-pri);
  font-weight: 700;
  -webkit-user-modify: read-write-plaintext-only;
  -moz-user-modify: read-write-plaintext-only;
}

table .editable:focus {
  outline: none;
}

.lg-table {
  border-collapse: separate;
  border-spacing: 0;
  border-bottom: 1px solid black;
  border-right: 1px solid black;

  th:not(:first-child) {
    border-left: none;
  }

  th:last-child {
    border-right: none;
  }

  tr {
    background: #fafafa;
  }

  th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
    border-right: 1px solid black;
    border-left: 1px solid black;
  }

  .total {
    border: none;
    td {
      border-top: 1px solid black;
    }
  }

  col {
    border: none;
  }
}
