progress {
  color: white;
  font-size: 12px;
  width: 100%;
  height: 8px;
  border: 1px solid var(--gray-sec);
  background: #ffffff;
  box-sizing: content-box;
  background-clip: content-box;
}

progress::-moz-progress-bar {
  color: white;
  background: var(--success-pri);
}

progress[value]::-webkit-progress-bar {
  background-color: white;
}

progress[value] {
  &::-webkit-progress-value {
    background-color: var(--success-pri);
    transition: 0.5s;
  }

  &.disabled::-webkit-progress-value {
    background-color: var(--success-sec);
  }
}
