@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;600;700;900&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700;900&amp;display=swap");

body {
  font-family: Lato;
}

.font-inter {
  font-family: Inter;
}
