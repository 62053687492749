.capitalization {
  .input-group {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 900px;

    .label {
      flex-shrink: 0;
      width: 100px;
    }
  }

  input:not(td input) {
    &[type="text"],
    &[type="number"] {
      width: 100%;
      max-width: 900px;
      padding-top: 8px !important;
      padding-bottom: 8px !important;
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  ol.questions {
    scroll-behavior: smooth;

    li {
      padding-bottom: 48px;

      .title {
        padding-bottom: 30px;
      }

      .radio-group {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      input[type="text"]::after {
        content: ">";
        position: absolute;
        background: var(--success-pri);
        color: white;
        right: 0px;
      }

      &:not(.active) {
        pointer-events: none;
        user-select: none;
        color: #c8c8c8;

        * {
          border-color: #f0f0f0;
          color: #c8c8c8 !important;

          &::placeholder,
          &:before {
            color: #c8c8c8;
          }
        }

        img {
          filter: brightness(0.5);
        }

        input[type="radio"] {
          opacity: 0.3;
        }
      }
    }
  }

  .term {
    font-weight: 600;
    border-bottom: 1px solid black;
    cursor: pointer;
  }
}
